import React, { PureComponent } from 'react';

import Home from './home';
import Invitation from './invitation';
import About from './about';
import LoveStory from './love-story';
import Gallery from './gallery';

class App extends PureComponent {
  componentDidMount() {
    this.addScript('js/main.js');
    if (window.ga) window.ga('send', 'pageview');
  }

  addScript = (src) => {
    const scriptId = encodeURI(src);

    if (!document.getElementById(src)) {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.id = scriptId;
      script.src = src;

      head.appendChild(script);
    }
  };

  render() {
    return (
      <>
        <Home />
        <Invitation />
        <About />
        <LoveStory />
        <Gallery />
      </>
    );
  }
}

export default App;

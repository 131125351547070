import React from 'react';

const About = () => {
  return (
    <section id="groom-bride-section" className="ftco-section bg-section">
      <div
        className="overlay-top"
        style={{ backgroundImage: 'url(images/top-bg.jpg)' }}
      ></div>
      <div
        className="overlay-bottom"
        style={{ backgroundImage: 'url(images/bottom-bg.jpg)' }}
      ></div>
      <div className="container">
        <div className="row justify-content-center pb-5">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <span className="clone">Bride & Groom</span>
            <h2 className="mb-3">Bride & Groom</h2>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-6 text-center d-flex align-items-stretch">
                <div className="bride-groom ftco-animate">
                  <div
                    className="img"
                    style={{ backgroundImage: 'url(images/groom.jpg)' }}
                  ></div>
                  <div className="text mt-4 px-4">
                    <h2>Duy Nguyen</h2>
                    <p>"She teaches me how to love."</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 text-center d-flex align-items-stretch">
                <div className="bride-groom ftco-animate">
                  <div
                    className="img"
                    style={{ backgroundImage: 'url(images/bride.jpg)' }}
                  ></div>
                  <div className="text mt-4 px-4">
                    <h2>Quynh Ngo</h2>
                    <p>"And he teaches me how to live."</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

import React from 'react';

const Home = () => {
  return (
    <section
      id="home-section"
      className="video-hero js-fullheight"
      data-stellar-background-ratio="0.5"
      style={{
        backgroundImage: 'url("images/bg_1.jpg")',
        backgroundPosition: '50% 0%',
        backgroundSize: 'cover',
        height: window.innerHeight,
      }}
    >
      <div className="overlay"></div>

      <div className="container" style={{ position: 'relative' }}>
        <div
          className="row js-fullheight justify-content-center d-flex align-items-center"
          style={{ height: window.innerHeight }}
        >
          <div className="col-md-12">
            <div className="text text-center">
              <div className="icon">
                <span className="flaticon-rose-outline-variant-with-vines-and-leaves"></span>
              </div>
              <span className="subheading" style={{ fontSize: '2vw' }}>
                The Wedding of
              </span>
              <h1 style={{ fontSize: '8vw' }}>Wel &amp; Quinn</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;

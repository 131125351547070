import React from 'react';

const Invitation = () => {
  return (
    <section
      className="ftco-section ftco-about ftco-no-pt ftco-no-pb"
      id="invitation-section"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="wrap">
              <div className="row d-flex">
                <div className="col-md-6 d-flex">
                  <div
                    className="img d-flex align-self-stretch align-items-center"
                    style={{ backgroundImage: 'url(images/about.jpg)' }}
                  ></div>
                </div>
                <div className="col-md-6 py-md-5 text">
                  <div className="py-md-4">
                    <div className="row justify-content-start pb-3">
                      <div className="col-md-12 ftco-animate p-4 p-lg-5 text-center">
                        <span className="subheading mb-4">
                          Join us to celebrate <br />
                          the wedding day of
                        </span>
                        <h2 className="mb-4">
                          Wel <span>&</span> Quinn
                        </h2>
                        <span className="icon flaticon-rose-variant-outline-with-vines"></span>
                        <span className="subheading">
                          Which is celebration on
                        </span>
                        <p className="time mb-4">
                          <span>Oct | 24 | 2020</span>
                        </p>
                        <span className="subheading mb-5">
                          Starting at 06:00 <br /> in the evening
                        </span>
                        <span className="subheading mb-5">
                          Cappella Gallery Hall <br /> Gosset - Rooftop <br />{' '}
                          <br />
                          24 3/2 Street, District 10 <br /> Ho Chi Minh City
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Invitation;

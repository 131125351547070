import React from 'react';

const Gallery = () => {
  return (
    <section style={{ paddingTop: '3em' }} id="gallery-section">
      <div className="container-fluid px-md-4">
        <div className="row justify-content-center pb-5">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <span className="clone">Photos</span>
            <h2 className="mb-3">Gallery</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-1.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-1.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-2.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-2.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-3.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-3.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-4.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-4.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-5.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-5.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-6.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-6.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-7.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-7.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
          <div className="col-md-3 ftco-animate">
            <a
              href="images/gallery-8.jpg"
              className="gallery img image-popup d-flex align-items-center justify-content-center"
              style={{ backgroundImage: 'url(images/gallery-thumbnail-8.jpg)' }}
            >
              <div className="icon d-flex align-items-center justify-content-center">
                <span className="ion-ios-image"></span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Gallery;

import React from 'react';

const LoveStory = () => {
  return (
    <section style={{ paddingTop: '3em' }} id="lovestory-section">
      <div className="container">
        <div className="row justify-content-center pb-5">
          <div className="col-md-12 text-center heading-section ftco-animate">
            <span className="clone">Love Story</span>
            <h2 className="mb-3">Love Story</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="timeline animate-box">
              <li className="animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: 'url(images/couple-1.jpg)' }}
                ></div>
                <div className="timeline-panel ftco-animate text-md-right">
                  <div className="overlay"></div>
                  <div className="timeline-heading">
                    <span className="date">Once upon a time</span>
                    <h3 className="timeline-title">Tuy Hoa</h3>
                  </div>
                  <div className="timeline-body">
                    <p>
                      There were a boy and a girl. They went to same highschool
                      but didn't know each other. They both thought they were
                      too ugly in the other's eyes
                    </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: 'url(images/couple-2.jpg)' }}
                ></div>
                <div className="timeline-panel ftco-animate">
                  <div className="overlay overlay-2"></div>
                  <div className="timeline-heading">
                    <span className="date">College time</span>
                    <h3 className="timeline-title">Saigon</h3>
                  </div>
                  <div className="timeline-body">
                    <p>
                      Time passby, they met in a party. She was with someone
                      else and so did he. They was drunk, singing, dancing,
                      having fun together but didn't belong to each other. Since
                      then, they went to the same party every year.
                    </p>
                  </div>
                </div>
              </li>
              <li className="animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: 'url(images/couple-3.jpg)' }}
                ></div>
                <div className="timeline-panel ftco-animate text-md-right">
                  <div className="overlay"></div>
                  <div className="timeline-heading">
                    <span className="date">Early of 2019</span>
                    <h3 className="timeline-title">In A Relationship</h3>
                  </div>
                  <div className="timeline-body">
                    <p>
                      After new year party, he found out she was single. He took
                      the chance, built a plan, and finally got her.
                    </p>
                  </div>
                </div>
              </li>
              <li className="timeline-inverted animate-box">
                <div
                  className="timeline-badge"
                  style={{ backgroundImage: 'url(images/couple-4.jpg)' }}
                ></div>
                <div className="timeline-panel ftco-animate">
                  <div className="overlay overlay-2"></div>
                  <div className="timeline-heading">
                    <span className="date">Feb 29, 2020</span>
                    <h3 className="timeline-title">We're Engaged</h3>
                  </div>
                  <div className="timeline-body">
                    <p>
                      After one year, he finally asked her in a fancy, romantic
                      restaurant. And a new chapter of their lives had begun.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoveStory;
